import './App.css';
import Heart from "./assets/heart.png";
import Vaish from "./assets/vaish.jpeg"

function App() {
  return (
    <div className="App">
      <h1 style={{padding: '1rem'}}>Happy Birthday Vaishnavi <img style={{height: '2rem', position: 'relative', top: '5px'}} src={Heart} /></h1>
      <h3 style={{padding: '1rem'}}>Wish you many many happy returns of the day !!!</h3>
      <img src={Vaish} style={{width: '80%'}}></img>
    </div>
  );
}

export default App;
